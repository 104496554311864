.imageBox {
    background-color: #eeeeee;
    height: 23vh;
}

.imageBox img{
    width: 92%;
}

.subImage {
    bottom: 3%;
}

.infoBox {
    height: 23vh;
}
.thisButton {
    width: 70%;
    background-color: var(--dyellow);
    color: #000
}


.icon {
    width: 12.5%;
}


.avatar {
    width: 10vw;
    height: 10vw;
    background-color: #eeeeee;
    border-radius: 100%;
}
.avatarTitle {
    width: 30.5vw;
    height: 8vw;
    background-color: #eeeeee;
}

.description {
    line-height: 2.9vh;
    word-wrap: break-word;
}
