.header {
    height: 27vh;
    background-color: #D9D9D9;
    border-radius: 0 0 35px 35px;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.avatar{
    width: 24vw;
    height: 24vw;
    background-color: #fff;
    border-radius: 100%;
    bottom: -20%;
    left: 11%;

    border: 6px solid #fff;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.avatar img {
    width: 90%;
    border-radius: 100%;
}

.content {
    margin-top: 7%;
}
.icon {
    width: 20%;
}

.thisButton {
    width: 46%;
    background-color: #397fe0;
}