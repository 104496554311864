@media (min-device-width: 480px) and (max-device-width: 1360px) {

    html, body {
        font-size: 1.55vw;
        line-height: 3vh;

    }

    .button {
        height: 4.6vh;
        font-size: 1.55vw;
    }


}