@font-face {
  font-family: 'dorsa-icon';
  src: url('../font/dorsa-icon.eot?64757511');
  src: url('../font/dorsa-icon.eot?64757511#iefix') format('embedded-opentype'),
       url('../font/dorsa-icon.woff2?64757511') format('woff2'),
       url('../font/dorsa-icon.woff?64757511') format('woff'),
       url('../font/dorsa-icon.ttf?64757511') format('truetype'),
       url('../font/dorsa-icon.svg?64757511#dorsa-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'dorsa-icon';
    src: url('../font/dorsa-icon.svg?64757511#dorsa-icon') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "dorsa-icon";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-logout:before { content: '\0101'; } /* 'ā' */
.icon-home:before { content: '\0102'; } /* 'Ă' */
.icon-down-open:before { content: '\0103'; } /* 'ă' */
.icon-left-open:before { content: '\0104'; } /* 'Ą' */
.icon-right-open:before { content: '\0105'; } /* 'ą' */
.icon-up-open:before { content: '\0106'; } /* 'Ć' */
.icon-down-open-1:before { content: '\0107'; } /* 'ć' */
.icon-left-open-1:before { content: '\0108'; } /* 'Ĉ' */
.icon-right-open-1:before { content: '\0109'; } /* 'ĉ' */
.icon-up-open-1:before { content: '\0110'; } /* 'Đ' */
.icon-share:before { content: '\0111'; } /* 'đ' */
.icon-logout-1:before { content: '\0112'; } /* 'Ē' */
.icon-heart-empty:before { content: '\0115'; } /* 'ĕ' */
.icon-heart:before { content: '\0116'; } /* 'Ė' */
.icon-ok-1:before { content: '\0117'; } /* 'ė' */
.icon-ok-4:before { content: '\0118'; } /* 'Ę' */
.icon-ok-3:before { content: '\0119'; } /* 'ę' */
.icon-cancel:before { content: '\0120'; } /* 'Ġ' */
.icon-cancel-1:before { content: '\0121'; } /* 'ġ' */
.icon-cancel-2:before { content: '\0122'; } /* 'Ģ' */
.icon-plus:before { content: '\0126'; } /* 'Ħ' */
.icon-plus-1:before { content: '\0127'; } /* 'ħ' */
.icon-plus-circle:before { content: '\0128'; } /* 'Ĩ' */
.icon-layers:before { content: '\0141'; } /* 'Ł' */
.icon-vector-pencil:before { content: '\0142'; } /* 'ł' */
.icon-pencil:before { content: '\0143'; } /* 'Ń' */
.icon-pencil-1:before { content: '\0144'; } /* 'ń' */
.icon-chart-pie-alt:before { content: '\0145'; } /* 'Ņ' */
.icon-chart-pie:before { content: '\0146'; } /* 'ņ' */
.icon-chart-pie-1:before { content: '\0147'; } /* 'Ň' */
.icon-chart-pie-2:before { content: '\0148'; } /* 'ň' */
.icon-menu:before { content: '\0149'; } /* 'ŉ' */
.icon-menu-1:before { content: '\0150'; } /* 'Ő' */
.icon-th-large:before { content: '\0151'; } /* 'ő' */
.icon-th-large-1:before { content: '\0152'; } /* 'Œ' */
.icon-th-thumb:before { content: '\0153'; } /* 'œ' */
.icon-link:before { content: '\0154'; } /* 'Ŕ' */
.icon-menu_vertical:before { content: '\0155'; } /* 'ŕ' */
.icon-schedule-calendar-icon-1:before { content: '\e800'; } /* '' */
.icon-globe-network-icon-1:before { content: '\e801'; } /* '' */
.icon-popup-link-icon-(1):before { content: '\e802'; } /* '' */
.icon-popup-link-icon:before { content: '\e803'; } /* '' */
.icon-copy-link-icon:before { content: '\e804'; } /* '' */
.icon-illustrator:before { content: '\e805'; } /* '' */
.icon-material_editor:before { content: '\e806'; } /* '' */
.icon-illustrator2:before { content: '\e807'; } /* '' */
.icon-illustrator1-2:before { content: '\e808'; } /* '' */
.icon-illustrator-1:before { content: '\e809'; } /* '' */
.icon-fill_color:before { content: '\e80a'; } /* '' */
.icon-example:before { content: '\e80b'; } /* '' */
.icon-birthday_cake:before { content: '\e80c'; } /* '' */
.icon-crayon:before { content: '\e80d'; } /* '' */
.icon-art_therapist:before { content: '\e80e'; } /* '' */
.icon-art_prices:before { content: '\e80f'; } /* '' */
.icon-calendar-icon:before { content: '\e810'; } /* '' */
