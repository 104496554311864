.container {

}
.header {
    height: 55vh;
    background-color: #D9D9D9;
    border-radius: 0 0 100px 100px;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.avatar{
    width: 16vw;
    height: 16vw;
    background-color: #fff;
    border-radius: 100%;
    bottom: -25.5%;
    left: 8%;

    border: 12px solid #fff;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.avatar img {
    width: 87%;
    border-radius: 100%;
}

.content {
    margin-top: 7%;
}
.icon {
    width: 15%;
}