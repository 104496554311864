.header {
    height: 10.5vh;
    /* border-bottom: 1px solid #ececec; */
    /* background-color: #f1f1f1; */
    box-shadow: 0px 0px 0px rgba(159,162,191,.18), 0 1px 9px rgba(159,162,191,.32);
}

.logo {
    width: 8vw;
}

.selected span{
    background-color: #EFBE0F;
    height: 0.75vh;
    bottom: 0;
    border-radius: 15px 15px 0 0;
}