.card:nth-child(3n) {
    margin-right: inherit;
}
.card {
    background-color: #eeeeee;
    margin-right: 5%;
}

.divider {
    border-bottom: 1px dashed #F2C614;
}