.mainTitle {
    height: 43vh;
    line-height: 11vh;

}

.miniDalTop {
    width: 0.8vw;
    top: 8%;
}
.miniDalRight {
    width: 0.8vw;
    bottom: 38%;
    right: 12%;
}
.miniDalLeft {
    width: 0.8vw;
    bottom: 38%;
    left: 12%;
}
.FullLogo {
    width: 37%;
}

.title {
    font-family: 'FractulAltBold','Mulish', sans-serif !important;
    line-height: 6vh;
}