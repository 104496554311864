.wrapper {
    position: absolute;
    background-color: #0000008c;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
}

.container {

    position: absolute;
    background-color: #fff;
    width: 100%;
    height: 30vh;
    border-radius: 34px 34px 0 0;
    top: 100%;
    z-index: 3;


}

.container.show{
    animation: BottomToTop ease 0.5s forwards;
    -webkit-animation: BottomToTop ease 0.5s forwards;
    -moz-animation: BottomToTop ease 0.5s forwards;
    -o-animation: BottomToTop ease 0.5s forwards;
    -ms-animation: BottomToTop ease 0.5s forwards;

}
@-webkit-keyframes BottomToTop {
    0% {
        top: 100%;
    }
    100% {
        top: 70%;
    }
}
@keyframes BottomToTop {
    0% {
        top: 100%;
    }
    100% {
        top: 70%;
    }
}

.container.close {
    animation: TopToBottom ease 0.5s forwards;
    -webkit-animation: TopToBottom ease 0.5s forwards;
    -moz-animation: TopToBottom ease 0.5s forwards;
    -o-animation: TopToBottom ease 0.5s forwards;
    -ms-animation: TopToBottom ease 0.5s forwards;
}
@-webkit-keyframes TopToBottom {
    0% {
        top: 70%;
    }
    100% {
        top: 100%;
    }
}
@keyframes TopToBottom {
    0% {
        top: 70%;
    }
    100% {
        top: 100%;
    }
}

.selected span{
    background-color: #EFBE0F;
    width: 2vw;
    height: 2vw;
    left: 5%;
    border-radius: 100%;
}

.container a{
    border-bottom: 0.5px dotted rgba(103, 103, 103, 0.4);
}