.mainTitle {
    height: 70vh;
    line-height: 11vh;


}

.miniDalTop {
    top: 9%;
}
.miniDalRight {
    bottom: 40%;
    right: 15%;
}
.miniDalLeft {
    bottom: 40%;
    left: 15%;
}
.FullLogo {
    width: 27.5%;
}
.title {
    font-family: 'FractulAltBold','Mulish', sans-serif !important;
}