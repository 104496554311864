@media (min-width: 992px) {

    html, body {
        font-size: 0.82vw;
        line-height: 4vh;
        height: 100%;

    }

    .button {
        height: 5vh;
        font-size: 0.8vw;
    }

    tr {
        position: relative;
        line-height: 5vh;
    }

}