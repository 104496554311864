.line {
    width: 0.5vw;
    height: 5.5vh;
    background-color: var(--dyellow);
    margin-right: 1.25vw;
}


@media (max-width: 480px) {
    .line {
        width: 1.5vw;
        height: 3.95vh;
        margin-right: 4vw;
    }

}

@media (min-device-width: 480px) and (max-device-width: 1360px) {

    .line {
        width: 0.7vw;
        height: 4.3vh;
        margin-right: 2.5vw;
    }


}


