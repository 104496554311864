.container{
    top: 0;
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
}

.image {
    width: 3vw;
}
.imageFullPage {
    width: 15vw;
}

@media (max-width: 480px) {
    .image {
        width: 10vw;
    }
    .imageFullPage {
        width: 55vw;
    }

}

@media (min-device-width: 480px) and (max-device-width: 1360px) {

    .image {
        width: 5.5vw;
    }
    .imageFullPage {
        width: 30vw;
    }


}
