.container {
    position: fixed;
    background-color: rgb(0 0 0 / 82%);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
}
.content {
    background-color: var(--dback);
    height: 63vh;
    box-shadow: 0 2px 16px rgba(159,162,191,.18), 0 2px 2px rgba(159,162,191,.32);
}