.container {
    height: 100vh;
}

.line {
    width: 2.75vw;
    height: 1vh;
    background-color: var(--dyellow);
}

.logo {
    width: 12%
}

.showLoading {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
}
.loadingContent {
    background-color: #f5f7f6;
    width: 40vw;
    overflow: hidden;
    box-shadow: 0 2px 16px rgba(159,162,191,.18), 0 2px 2px rgba(159,162,191,.32);
}
.loadingContent img{
    width: 60%;
}

.backButton {
    width: 30%;
    background-color: var(--dyellow);
    color: #000
}

.linkImage {
    width: 38% !important;
}


.inputClass :global(textarea){
    height: 20vh!important;
    /*word-wrap: break-word;
    word-break: break-all;*/
    padding: 2vh 1vw;
    resize: none;
    line-height: 2.5vh;
}
.subLabel {
    line-height: 2.75vh;
}

.smallLead :global(.lead) {
    width: 100% !important;
    border: 0.75vh solid #e7e7e7 !important;
}



.zoneBox {
    width: 100%;
    padding: 2vh 1vw;
    border-radius: 10px;
    border: 0.3vh dotted var(--cardBorder);
}
.cancel {
    top: 2%;
    right: 3%;
}
.zone {
    padding: 2vh 1vw;
    height: 50vh;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: var(--cardHeader);
}

.zoneLogo {
    width: 100%;
}

.thisButton {
    width: 30%;
    background-color: var(--dyellow);
    color: #000
}
.thisButtonMeta {
    width: 15%;
    background-color: var(--dyellow);
    color: #000
}

.metaIcon {
    width: 10%;
}


.divider {
    border-bottom: 2px dashed #F2C614;
}

.addButton {
    background-color: #2F71FBFF;
    color: #fff
}

.badge:nth-child(2n){
    margin-right: inherit;
}