.box {
    border: 1px solid #0000002b;
    /*box-shadow: 0 2px 16px rgba(159,162,191,.18), 0 2px 2px rgba(159,162,191,.32);*/
}

.divider {
    border-bottom: 1px dashed #F2C614;
}

.container div {
    margin-right: 2%;
}
.container div:nth-child(3n) {
    margin-right: inherit;
}