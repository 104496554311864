
.subImage {
    bottom: 3%;
}

.sidebar {
    border-left: 2px dotted #c3c3c3;
}

.imageBox {
    background-color: #eeeeee;
    height: 45vh;
}

.imageBox img{
    width: 92%;
}


.infoBox {
    height: 45vh;
}
.title {
    background-color: #eeeeee;
}

.icon {
    width: 12.5%;
}

.thisButton {
    width: 20%;
    background-color: var(--dyellow);
    color: #000
}

.avatar {
    width: 2.5vw;
    height: 2.5vw;
    background-color: #eeeeee;
    border-radius: 100%;
}
.avatarTitle {
    width: 5vw;
    height: 2vw;
    background-color: #eeeeee;
}

.description {
    line-height: 3.4vh;
    word-wrap: break-word;
}