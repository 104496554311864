.container {

    height: 30vh;
    /*border: 1px solid #282a36;*/
    box-shadow: 0 2px 16px rgba(159,162,191,.18), 0 2px 2px rgba(159,162,191,.32);

    margin-bottom: 4%;
    margin-right: 4%;

}
.container:nth-child(2n) {
    margin-right: inherit;
}


.image {

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #eeeeee;
    border-radius: 8px 8px 0 0;
}

.avatar{
    width: 11.5vw;
    height: 11.5vw;
    background-color: #fff;
    border-radius: 100%;
    bottom: -28.5%;
    border: 7px solid #fff;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.avatar img {
    width: 87%;
    border-radius: 100%;
}