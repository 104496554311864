.header {
    height: 35vh;
    background-color: #D9D9D9;
    border-radius: 0 0 35px 35px;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.avatar{
    width: 20vw;
    height: 20vw;
    background-color: #fff;
    border-radius: 100%;
    bottom: -21.2%;
    left: 7%;
    border: 12px solid #fff;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.avatar img {
    width: 90%;
    border-radius: 100%;
}

.content {
    margin-top: 5%;
}
.icon {
    width: 17.4%;
}

.thisButton {
    width: 46%;
    background-color: #397fe0;
}