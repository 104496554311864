.container {

    height: 100vh;

    /*background-color: #d73e36;*/
    /* top: 0;
     bottom: 0;
     width: 100%;
     position: absolute;*/

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    overflow: hidden;


}

.content {
    height: 92vh;
}

.footer {
    height: 10vh;
    /* border-bottom: 1px solid #ececec; */
    /* background-color: #f1f1f1; */
    box-shadow: 0px 0px 0px rgba(159,162,191,.18), 0 1px 9px rgba(159,162,191,.32);
}

