.inputClass :global(textarea){
    height: 20vh!important;
    /*word-wrap: break-word;
    word-break: break-all;*/
    padding: 2vh 1vw;
    resize: none;
    line-height: 2.5vh;
}

.thisButton {
    width: 15%;
    background-color: var(--dyellow);
    color: #000
}



.wrapper {
    position: fixed;
    background-color: rgb(0 0 0 / 82%);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
}
.wrapperContent {
    background-color: var(--dback);
    height: 63vh;
    box-shadow: 0 2px 16px rgba(159,162,191,.18), 0 2px 2px rgba(159,162,191,.32);
}
.loading {
    width: 15vw;
}
.linkImage {
    width: 38% !important;
}

.backButton {
    width: 30%;
    background-color: var(--dyellow);
    color: #000
}



.inputClass :global(textarea){
    height: 20vh!important;
    /*word-wrap: break-word;
    word-break: break-all;*/
    padding: 2vh 1vw;
    resize: none;
    line-height: 2.5vh;
}
.subLabel {
    line-height: 2.75vh;
}

.smallLead :global(.lead) {
    width: 100% !important;
    border: 0.75vh solid #e7e7e7 !important;
}


.addButton {
    background-color: #2F71FBFF;
    color: #fff
}

.badge:nth-child(2n){
    margin-right: inherit;
}


.divider {
    border-bottom: 2px dashed #F2C614;
}

.selectInput {

}
/*.selectInput :global(.lead) {
    width: 100% !important;
    border: 0.75vh solid #e7e7e7 !important;
}*/



/*:global( .rmdpInput input) {
    border-radius: 15px;
    border: 1px #0c8af8 solid;
    padding: 4px 12px;
    background-color: white;
    height: 5vh;
    width: 80%;
    box-shadow: 0 0 2px #0074d9;
}*/

/*
.rmdp-input {
    border-radius: 15px;
    border: 1px #0c8af8 solid;
    padding: 4px 12px;
    background-color: white;
    height: 7vh !important;
    width: 80%;
    box-shadow: 0 0 2px #0074d9;
}*/


/*
.inputClass :global(select){
    height: 5vh!important;
}

:global .select__control {
    height: 5vh!important;
}
:global .select__control {
    height: 5vh!important;
}
:global .select__input-container {
    height: 5vh!important;
    margin: 0;
    padding: 0;
}
:global .select__indicators {
    height: 5vh!important;
}
:global .select__input {
    height: 5vh!important;
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
    width: 23% !important;
}
:global .select__input-container {
    height: 5vh!important;
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
    width: 23% !important;
}*/

.avatar {
    width: 3vw;
    height: 3vw;
    background-color: #dfdfdf;
    border-radius: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
