.container {
    min-height: 17vh;
    background-color: #dfdfdf;
    box-shadow: 0 2px 16px rgba(159,162,191,.18), 0 2px 2px rgba(159,162,191,.32);
}

.avatar {
    width: 17vw;
    height: 17vw;
    background-color: #dfdfdf;
    border-radius: 100%;
    top: -6vh;
    left: 10%;
    border: 7px solid #fff;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}


.activeHide {
    filter: grayscale(1);
}
.title {
    margin-top: 8.15vh;
}
.divider {
    border-bottom: 2px dashed #ffffff;
}
.image {

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #eeeeee;
    border-radius: 8px 8px 0 0;
}

.badge {
    top: -5vh;
    left: 5%;
    background-color: #EFBE0F;
//filter: invert(77%) sepia(9%) saturate(6188%) hue-rotate(2deg) brightness(105%) contrast(88%);

}