.inputClass :global(textarea){
    height: 20vh!important;
    /*word-wrap: break-word;
    word-break: break-all;*/
    padding: 2vh 1vw;
    resize: none;
    line-height: 2.5vh;
}


.thisButton {
    width: 15%;
    background-color: var(--dyellow);
    color: #000
}



.wrapper {
    position: fixed;
    background-color: rgb(0 0 0 / 82%);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
}
.wrapperContent {
    background-color: var(--dback);
    height: 63vh;
    box-shadow: 0 2px 16px rgba(159,162,191,.18), 0 2px 2px rgba(159,162,191,.32);
}
.loading {
    width: 15vw;
}
.linkImage {
    width: 38% !important;
}

.backButton {
    width: 30%;
    background-color: var(--dyellow);
    color: #000
}

