@media (max-width: 480px) {

    html, body {
        font-size: 2.9vw;
        line-height: 3.6vh;

    }

    .button {
        height: 5vh;
    }

    .fs-0-5 {
        font-size: 0.5rem;
    }

    .fs-0-6 {
        font-size: 0.6rem;
    }

    .fs-0-7 {
        font-size: 0.7rem;
    }

    .fs-0-8 {
        font-size: 0.8rem;
    }

    .fs-0-9 {
        font-size: 0.9rem;
    }

    .fs-01 {
        font-size: 1.1rem;
    }

    .fs-02 {
        font-size: 1.2rem;
    }

    .fs-03 {
        font-size: 1.31rem;
    }

    .fs-04 {
        font-size: 1.4rem;
    }

    .fs-05 {
        font-size: 1.5rem;
    }

    .fs-06 {
        font-size: 1.6rem;
    }

    .fs-07 {
        font-size: 1.7rem;
    }

    .fs-08 {
        font-size: 1.8rem;
    }

    .fs-09 {
        font-size: 1.9rem;
    }

    .fs-20 {
        font-size: 2rem;
    }

    .fs-21 {
        font-size: 2.1rem;
    }

    .fs-22 {
        font-size: 2.2rem;
    }

    .fs-50 {
        font-size: 5rem;
    }
    .fs-30 {
        font-size: 3rem;
    }
    .fs-40 {
        font-size: 4rem;
    }
    .fs-45 {
        font-size: 4.5rem;
    }
    .fs-60 {
        font-size: 6rem;
    }
    .fs-70 {
        font-size: 7rem;
    }
}