.createCollection {
    border: 1px dashed #000000;
}

.thisButton {
    color: #000
}
.thisButton.active {
    background-color: var(--dyellow);
}

.container {
    min-height: 100%;
}