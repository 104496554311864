.container {

    height: 100vh;

    /*background-color: #d73e36;*/
   /* top: 0;
    bottom: 0;
    width: 100%;
    position: absolute;*/


}

.content {
    height: 89.5vh;
}

.footer {
    height: 10.5vh;
    /* border-bottom: 1px solid #ececec; */
    /* background-color: #f1f1f1; */
    box-shadow: 0px 0px 0px rgba(159,162,191,.18), 0 1px 9px rgba(159,162,191,.32);
}