.container {

    height: 30vh;
    /*border: 1px solid #282a36;*/
    box-shadow: 0 2px 16px rgba(159,162,191,.18), 0 2px 2px rgba(159,162,191,.32);
    margin-right: 6%;
    margin-bottom: 6%;

}

.container:nth-child(2n) {
    margin-right: inherit;
}



.container img {
    width: 60%;
}

.image {
    background-color: #eeeeee;
    border-radius: 8px 8px 0 0;
}