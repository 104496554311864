.container {
    background-color: rgb(4 4 3 / 81%);
    height: 100vh;
    top: 0;
    width: 100%;
    right: 0;
    z-index: 2;
    left: 0;
    position: fixed;
}

.subLabel {
    line-height: 2.75vh;
}

.content {
    background-color: var(--dback);
    height: 70vh;
}



.zoneBox {
    width: 100%;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2vh 1vw;
    border-radius: 10px;
    border: 0.3vh dotted var(--cardBorder);
}
.cancel {
    top: 2%;
    right: 3%;
    z-index: 1;
}
.zone {
    padding: 2vh 1vw;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    background-color: var(--cardHeader);
}

.zoneLogo {
    width: 100%;
}

.thisButton {
    width: 10vw;
    background-color: var(--dyellow);
    border: 1px solid var(--dyellow);
    color: #000
}
.thisButtonBack {
    width: 10vw;
    border: 1px solid var(--dyellow);
    color: #000
}

.content :global( model-viewer ){
    width: 60vw;
    height: 40vh;
    margin:0 auto;
    /* box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.67);*/
    border-radius: 8px;
    background-color: #ececed
}