.card:nth-child(4n) {
    margin-right: inherit;
}
.card {
    background-color: #eeeeee;
    margin-right: 2.66%;
}

.divider {
    border-bottom: 1px dashed #F2C614;
}