:global( model-viewer ){
    width: 80vw;
    height: 80vh;
    margin:0 auto;
    /* box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.67);*/
    border-radius: 8px;
    background-color: #ececed
}

@media (max-width: 480px) {
    :global( model-viewer ){
        width: 86vw;
        height: 33vh;
    }


}
@media (min-device-width: 480px) and (max-device-width: 1360px) {

    :global( model-viewer ){
        width: 86vw !important;
        height: 50vh !important;
    }


}
