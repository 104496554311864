.container {

    height: 43vh;
    /*border: 1px solid #282a36;*/
    box-shadow: 0 2px 16px rgba(159,162,191,.18), 0 2px 2px rgba(159,162,191,.32);
    margin-right: 2.66%;
    margin-bottom: 2.66%;

}

.container:nth-child(4n) {
    margin-right: inherit;
}



.container img {
    width: 70%;
}

.image {
    background-color: #eeeeee;
    border-radius: 8px 8px 0 0;
}