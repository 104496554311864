.mainTitle {
    height: 42vh;
    line-height: 11vh;

}

.miniDalTop {
    width: 1.15vw;
    top: 9%;
}
.miniDalRight {
    width: 1.15vw;
    bottom: 38%;
    right: 12%;
}
.miniDalLeft {
    width: 1.15vw;
    bottom: 38%;
    left: 12%;
}
.FullLogo {
    width: 50%;
}

.title {
    font-family: 'FractulAltBold','Mulish', sans-serif !important;
    line-height: 5.1vh;
}