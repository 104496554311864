.inputGroup {
   /* margin: 3vh 0 6vh;*/
    position: relative;
}

.inputGroup input:focus {
    border: 1px solid #000 !important;
}
.inputGroup textarea:focus {
    border: 1px solid #000 !important;
}

.inputGroup.hasError input{
    border-color: #214fbb !important;
}
.inputGroup.hasError textarea{
    border-color: #214fbb !important;
}

.inputGroup label {

    /* top: 0vh; */
    direction: ltr;
    /*font-size: 1vw;*/
    background-color: #fff;
    transition: all 0.5s;
    z-index: 1;
    margin-bottom: 0.5vh;
}

.subLabel {
    line-height: 2.75vh;
}

:global(.ltr) .inputGroup label {

}

@media (max-width: 480px) {
    .inputGroup label {
        right: 3%;
        padding: 0 2vw;
    }
}

.thisIcon {
    position: absolute;
    left: 2.5%;
}


.inputGroup input {
    width: 100%;
    height: 5vh;
    border: 0.8px solid #949494;
    border-radius: 6px;
    font-style: normal;
    font-family: iranyekan, serif;
    /* padding: 10px 15px; */
    /* margin: 1vh auto 1vh; */
    position: relative;
    direction: ltr;
    display: block;
    padding: 0 1vw;
}

/*.inputGroup .selectBox {
    width: 100%;
    height: 5vh !important;;
    border: 0.8px solid #949494;
    border-radius: 6px;
    font-style: normal;
    font-family: iranyekan, serif;
    !* padding: 10px 15px; *!
    !* margin: 1vh auto 1vh; *!
    position: relative;
    direction: ltr;
    display: block;
    padding: 0 1vw;
}
.inputGroup .selectBox :global(.select__control)  {
    width: 100%;
    height: 5vh !important;;
    font-style: normal;
    font-family: iranyekan, serif;
    !* padding: 10px 15px; *!
    !* margin: 1vh auto 1vh; *!
    position: relative;
    direction: ltr;
}
.inputGroup .selectBox :global(.select__value-container)  {
    width: 100%;
    height: 5vh !important;
    font-style: normal;
    font-family: iranyekan, serif;
    !* padding: 10px 15px; *!
    !* margin: 1vh auto 1vh; *!
    position: relative;
    direction: ltr;
    padding: 0 !important;
    margin: 0 !important;
    border: none !important;;
}*/

.inputGroup textarea {
    width: 100%;
    height: 5vh;
    border: 0.8px solid #949494;
    border-radius: 6px;
    font-style: normal;
    font-family: iranyekan, serif;
    /* padding: 10px 15px; */
    /* margin: 1vh auto 1vh; */
    position: relative;
    direction: ltr;
    display: block;
    padding: 0 1vw;
}


:global(.ltr) .inputGroup input {
    font-family: 'FractulAlt','Mulish', sans-serif !important;
}

:global(.ltr) .inputGroup textarea {
    font-family: 'FractulAlt','Mulish', sans-serif !important;
}

.inputGroup.show-error input:focus {
    border-color: #214fbb !important;
}
.inputGroup.show-error textarea:focus {
    border-color: #214fbb !important;
}
.inputGroup.show-error input:focus + label {
    color: #214fbb;
}
.inputGroup.show-error textarea:focus + label {
    color: #214fbb;
}
.inputGroup.show-error label {
    color: #214fbb;
}
.inputGroup.show-error input {
    border-color: #214fbb;
}
.inputGroup.show-error textarea {
    border-color: #214fbb;
}
.inputGroup.show-error .error-box img, .inputGroup.show-error .error-box p {
    display: block;
}
.inputGroup.show-error.password-input .pass-icon {
    display: none;
}
.inputGroup .error-box img {
    width: 5vw;
    top: 3.68vh;
    left: 15.25vw;
    display: none;
    position: absolute;
    filter: invert(49%) sepia(67%) saturate(6415%) hue-rotate(342deg) brightness(98%) contrast(94%);
}
.inputGroup .error-box p {
    display: none;
    text-align: right;
    padding: 0.5vh 15vw;
    margin-bottom: -0.75vh;
    filter: invert(49%) sepia(67%) saturate(6415%) hue-rotate(342deg) brightness(98%) contrast(94%);
}
.inputGroup input:focus + label {
    /*left: 3.5vw;
    top: 0.2vh;
    font-size: 1.25vw;
    z-index: 1;*/
    /*left: 8vw;*/
    top: -30%;
    font-size: 0.9rem;
    z-index: 1;
    transition: top ease 5s;
}
.inputGroup textarea:focus + label {
    /*left: 3.5vw;
    top: 0.2vh;
    font-size: 1.25vw;
    z-index: 1;*/
    /*left: 8vw;*/
    top: -30%;
    font-size: 0.9rem;
    z-index: 1;
    transition: top ease 5s;
}
.inputGroup .hasValue {
    /*left: 8vw !important;*/
    top: -30% !important;
    font-size: 0.9rem !important;
    /*left: 3.5vw !important;
    top: 0.2vh !important;
    font-size: 1.25vw !important;*/
}


.selectBox {
    width: 100% !important;
    font-size: 0.75vw;
    direction: ltr !important;
}